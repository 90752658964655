import React, { useState, useEffect, useRef } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import { Context } from '../../../../types/frontastic'
import tagManager from '../../../../domain/TagManager'
import GtmService from '../../../../services/gtm'
import OldTagManager from '../../../../domain/OldTagManager'
import OldGtmService from '../../../../services/oldGtm'
import Slider from '../../../atoms/slider'
import ProductTile from '../Hits/ProductTile'
import ProductColorService from '../../../../services/color'
import useConsentManagement from 'anwr_sport2000/src/js/services/hook/useConsentManagement'

type Props = {
  hits: any
  context: Context
  objectId: string | null
  gtmCategoryName: string
  gtmCategoryId: string
  renderAsSlider: boolean
  swiperColor: string
  alignment: string
  node: object
  nameCategory: any
} & WrappedComponentProps

const ProductHits: React.FC<Props> = ({
  hits,
  context,
  gtmCategoryName,
  gtmCategoryId,
  renderAsSlider,
  swiperColor,
  alignment,
  node,
  nameCategory,
}) => {
  const [colors, setColors] = useState<Record<string, any>>({})
  const [isProductImpressTracked, setIsProductImpressTracked] = useState(false)
  const observer = useRef<IntersectionObserver | null>(null)
  const { isGtmAccepted } = useConsentManagement()

  // Handle GTM logic and color fetching on mount
  useEffect(() => {
    if (isGtmAccepted && hits?.length > 0 && !isProductImpressTracked) {
      const gtmService = new GtmService(hits)
      const productListGTM = gtmService.createProductListGTM(gtmCategoryName, gtmCategoryId)
      tagManager.productImpressions(productListGTM, gtmCategoryName, gtmCategoryId)

      const oldGtmService = new OldGtmService(hits)
      const oldProductListGTM = oldGtmService.createProductListGTM(`Page: ${gtmCategoryName}`)
      OldTagManager.productImpressions(oldProductListGTM)
      OldTagManager.hitViewdImpressions()
      setIsProductImpressTracked(true)
    }
  }, [gtmCategoryName, gtmCategoryId, hits, isGtmAccepted, isProductImpressTracked]);

  useEffect(() => {

    // Fetch product colors
    const modelKeys: string[] = []
    const updatedColors = { ...colors }

    hits.forEach((hit: any) => {
      const productColors = updatedColors[hit.root_model_key] ?? null
      if (productColors == null) {
        updatedColors[hit.root_model_key] = [hit]
        modelKeys.push(hit.root_model_key)
      }
    })

    if (modelKeys.length > 0) {
      ProductColorService.getColors(modelKeys).then((responseColors) => {
        responseColors?.forEach((hitColor: any) => {
          const hitColors = updatedColors[hitColor.root_model_key] ?? []
          const objectIds = hitColors.map((hit: any) => hit.product_id)
          if (!objectIds.includes(hitColor.product_id)) {
            hitColors.push(hitColor)
          }
          updatedColors[hitColor.root_model_key] = hitColors
        })
        setColors(updatedColors)
      })
    }
  }, [hits])

  // Cleanup observer on unmount
  useEffect(() => {
    return () => observer.current?.disconnect()
  }, [])

  const bindColors = (hits: any[], colors: Record<string, any>) => {
    if (!colors || hits.length === 0) return hits

    return hits.map((hit) => {
      if (hit.root_model_key) {
        hit.colors = []
        const productColors = colors[hit.root_model_key] ?? []
        productColors.forEach((productColor: any) => {
          productColor.isActive = productColor.sku === hit.sku
          hit.colors.push(productColor)
        })
      }
      return hit
    })
  }

  const renderProductTilesAsList = () => {
    const { algoliaIndexName } = context?.projectConfiguration || {}
    const updatedHits = bindColors(hits, colors)

    return updatedHits.map((hit, index) => (
      <ProductTile
        key={hit.product_id + index}
        position={index + 1}
        product={hit}
        nameCategory={nameCategory || gtmCategoryName}
        categoryId={gtmCategoryId}
        colors={hit.colors}
        // @ts-ignore
        node={node}
        algoliaIndexName={algoliaIndexName}
      />
    ))
  }

  const renderProductTilesAsSlider = () => {
    const { algoliaIndexName } = context?.projectConfiguration || {}
    const updatedHits = bindColors(hits, colors)

    return (
      <Slider
        spaceBetween={0}
        slidesPerView={2}
        slidesPerGroup={2}
        loop={false}
        swiperColor={swiperColor}
        breakpoints={{
          768: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 12 },
          1024: { slidesPerView: 5, slidesPerGroup: 5, spaceBetween: 20 },
        }}
      >
        {updatedHits.map((hit, index) => (
          <SwiperSlide key={hit.product_id}>
            <ProductTile
              key={hit.product_id + index}
              position={index + 1}
              product={hit}
              nameCategory={nameCategory || gtmCategoryName}
              categoryId={gtmCategoryId}
              colors={hit.colors}
              // @ts-ignore
              node={node}
              algoliaIndexName={algoliaIndexName}
            />
          </SwiperSlide>
        ))}
      </Slider>
    )
  }

  if (!hits) return null

  return (
    <div className="product-list--wrapper product-list-algolia product-tiles-algolia-wrapper">
      <div className="product-list--component">
        {!renderAsSlider ? (
          <div
            className={classnames('product-teaser-list', {
              'justify-start': alignment === 'left',
              'justify-center': alignment === 'center',
              'justify-end': alignment === 'right',
            })}
          >
            {renderProductTilesAsList()}
          </div>
        ) : (
          renderProductTilesAsSlider()
        )}
      </div>
    </div>
  )
}

export default injectIntl(ProductHits)
